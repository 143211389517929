import React from 'react';

import Layout from '../components/Layout';
import {Helmet} from "react-helmet";
import ScheduleForm from '../components/ScheduleForm.js'

export const TreatmentCarePageTemplate = () => {

  setTimeout(() => {
    const $ = window.$;
    window.document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener('click', function (e) { e.preventDefault(); });
    });

    $(".nav-tabs a").click(function () {
      $(this).tab('show');
    });



    function reset() {
      $('.changesrc').attr("src", "/assets/icons/treatment/blue/1.png");
      $('.changesrc1').attr("src", "/assets/icons/treatment/blue/2.png");
      $('.changesrc2').attr("src", "/assets/icons/treatment/blue/3.png");
      $('.changesrc3').attr("src", "/assets/icons/treatment/blue/4.png");
      $('.changesrc4').attr("src", "/assets/icons/treatment/blue/5.png");
      $('.changesrc5').attr("src", "/assets/icons/treatment/blue/6.png");
    }
    $('#list1').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab1').hasClass('active')) {
          $('.desk-icons1').addClass("changesrc");
          $('.changesrc').attr("src", "/assets/icons/treatment/white/1.png")
        }

      });
    });

    $('#list2').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab2').hasClass('active')) {
          $('.desk-icon2').addClass("changesrc1");
          $('.changesrc1').attr("src", "/assets/icons/treatment/white/2.png")
        }
      })
    })

    $('#list3').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab3').hasClass('active')) {
          $('.desk-icon3').addClass("changesrc2");
          $('.changesrc2').attr("src", "/assets/icons/treatment/white/3.png")
        }
      })
    })

    $('#list4').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab4').hasClass('active')) {
          $('.desk-icon4').addClass("changesrc3");
          $('.changesrc3').attr("src", "/assets/icons/treatment/white/4.png")
        }
      })
    })

    $('#list5').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab5').hasClass('active')) {
          $('.desk-icon5').addClass("changesrc4");
          $('.changesrc4').attr("src", "/assets/icons/treatment/white/5.png")
        }
      })
    })
    $('#list6').click(function() {
      reset()
      $('.nav-link').each(function() {
        if ($('#link-tab6').hasClass('active')) {
          $('.desk-icon6').addClass("changesrc5");
          $('.changesrc5').attr("src", "/assets/icons/treatment/white/6.png")
        }
      })
    })

  }, 1000);
  return (
    <main role="main" className="treatment-care-page">
      <Helmet>
        <title>Treatment & Care</title>
      </Helmet>
      <div className="container-fluid bg-gray pt-5 pb-4 text-center">
        <div className="container pt-5">
          <div className="w-75 mx-auto mb-3 mob-wid">
            <div className="pb-4">
              <h2>Whatever you need, wherever you need it</h2>
              <p className="text-center">Our licensed physical therapists come to<br/>
                your home or office to work on a wide variety of conditions.</p>
            </div>
            <div className="input-group subscribe ca-shadow-sm rounded-2">
              <input type="text" className="form-control border-0 rounded-2" placeholder="Search"/>
              <div className="input-group-append bg-white rounded-2"/>
            </div>
          </div>
          <br/>
          <div className="mt-3 tab-wrap ca-shadow-sm rounded bg-white">
            {/* Nav tabs */}
            <ul className="nav nav-tabs nav-justified">
              <li className="nav-item" id="list1">
                <a id="link-tab1" className="nav-link active" href="#menu0" style={{height: '100%'}}><img
                  id="desk-icon1" className="desk-icon1 changesrc py-10p" src="/assets/icons/treatment/white/1.png"
                  alt=""/><br/>
                  Orthopedic Injuries</a>
              </li>
              <li className="nav-item" id="list2">
                <a id="link-tab2" className="nav-link" href="#menu1" style={{height: '100%'}}><img id="desk-icon2"
                                                                                                   className="desk-icon2 py-10p"
                                                                                                   src="/assets/icons/treatment/blue/2.png"
                                                                                                   alt=""/><br/>
                  Senior Care</a>
              </li>
              <li className="nav-item" id="list3">
                <a id="link-tab3" className="nav-link" href="#menu2" style={{height: '100%'}}><img id="desk-icon3"
                                                                                                   className="desk-icon3 py-10p"
                                                                                                   src="/assets/icons/treatment/blue/3.png"
                                                                                                   alt=""/><br/>
                  Post-Surgical Rehabilitation</a>
              </li>
              <li className="nav-item" id="list4">
                <a id="link-tab4" className="nav-link" href="#menu3" style={{height: '100%'}}><img id="desk-icon4"
                                                                                                   className="desk-icon4 py-10p"
                                                                                                   src="/assets/icons/treatment/blue/4.png"
                                                                                                   alt=""/><br/>
                  Back and Neck Pain</a>
              </li>
              <li className="nav-item" id="list5">
                <a id="link-tab5" className="nav-link" href="#menu4" style={{height: '100%'}}><img id="desk-icon5"
                                                                                                   className="desk-icon5 py-10p"
                                                                                                   src="/assets/icons/treatment/blue/5.png"
                                                                                                   alt=""/><br/>
                  Specialty Care</a>
              </li>
              <li className="nav-item" id="list6">
                <a id="link-tab6" className="nav-link" href="#menu5" style={{height: '100%'}}><img id="desk-icon6"
                                                                                                   className="desk-icon6 py-10p"
                                                                                                   src="/assets/icons/treatment/blue/6.png"
                                                                                                   alt=""/><br/>
                  Focused Train</a>
              </li>
            </ul>
            {/* Tab panes */}
            <div className="tab-content border-0 mb-3 py-4">
              {/* start of menu data */}
              <div id="menu0" className="container tab-pane active">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>Adhesive capsulitis</p>
                    <p>Bursitis</p>
                    <p>Carpal tunnel syndrome</p>
                    <p>Dislocation</p>
                    <p>Fracture</p>
                  </div>
                  <div className="col-md-3">
                    <p>Golfer's elbow (medial epicondylitis)</p>
                    <p>Iliotibial band syndrome</p>
                    <p>Joint pain</p>
                    <p>Labral tear</p>
                    <p>Ligament tear</p>
                  </div>
                  <div className="col-md-3">
                    <p>Meniscus tear</p>
                    <p>Patellofemoral syndrome</p>
                    <p>Piriformis syndrome</p>
                    <p>Plantar fasciitis</p>
                    <p>Shoulder impingement</p>
                  </div>
                  <div className="col-md-3">
                    <p>Shin splints</p>
                    <p>Sprain/ strain</p>
                    <p>Tendonitis</p>
                    <p>Tennis elbow (lateral epicondylitis)</p>
                    <p>Thoracic outlet syndrome</p>
                  </div>
                </div>
              </div>
              <div id="menu1" className="container tab-pane">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>Arthritis</p>
                    <p>Balance disorders</p>
                    <p>Degenerative disc disease</p>
                  </div>
                  <div className="col-md-3">
                    <p>Degenerative joint disease</p>
                    <p>Difficulty walking</p>
                    <p>Fall prevention</p>
                  </div>
                  <div className="col-md-3">
                    <p>Joint pain</p>
                    <p>Muscle weakness</p>
                    <p>Osteoarthritis</p>
                  </div>
                  <div className="col-md-3">
                    <p>Osteoporosis</p>
                    <p>Posture training</p>
                    <p>Stenosis</p>
                  </div>
                </div>
              </div>
              <div id="menu2" className="container tab-pane">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>ACL reconstruction</p>
                    <p>Arthroscopic procedures</p>
                    <p>Bunionectomy</p>
                    <p>Carpal tunnel release</p>
                    <p>Fracture fixation</p>
                  </div>
                  <div className="col-md-3">
                    <p>Hammertoe correction</p>
                    <p>Hip labral repair</p>
                    <p>Hip replacement</p>
                    <p>Knee replacement</p>
                    <p>Meniscus repair</p>
                  </div>
                  <div className="col-md-3">
                    <p>Rotator cuff repair</p>
                    <p>Shoulder labral repair</p>
                    <p>Shoulder replacement</p>
                    <p>Spinal artificial disc replacement</p>
                    <p>Spinal discectomy</p>
                  </div>
                  <div className="col-md-3">
                    <p>Spinal foraminotomy</p>
                    <p>Spinal fusion</p>
                    <p>Spinal laminectomy</p>
                    <p>Subacromial decompression</p>
                  </div>
                </div>
              </div>
              <div id="menu3" className="container tab-pane">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>Ankylosing spondylitis</p>
                    <p>Degenerative disc disease</p>
                    <p>Disc bulge</p>
                    <p>Disc herniation</p>
                  </div>
                  <div className="col-md-3">
                    <p>Headache</p>
                    <p>Low back pain</p>
                    <p>Mid back pain</p>
                    <p>Neck pain</p>
                  </div>
                  <div className="col-md-3">
                    <p>Sciatica</p>
                    <p>Spondylosis</p>
                    <p>Spondylolisthesis</p>
                  </div>
                  <div className="col-md-3">
                    <p>Stenosis</p>
                    <p>Upper back pain</p>
                    <p>Whiplash</p>
                  </div>
                </div>
              </div>
              <div id="menu4" className="container tab-pane">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>Complex regional pain syndrome</p>
                    <p>Fibromyalgia</p>
                  </div>
                  <div className="col-md-3">
                    <p>Multiple sclerosis</p>
                    <p>Parkinson's</p>
                  </div>
                  <div className="col-md-3">
                    <p>Pregnancy and postpartum care</p>
                  </div>
                  <div className="col-md-3">
                    <p>Stroke</p>
                  </div>
                </div>
              </div>
              <div id="menu5" className="container tab-pane">
                <br/>
                <div className="row text-left px-3">
                  <div className="col-md-3">
                    <p>Aquatic Therapy</p>
                    <p>Core stabilization</p>
                    <p>Core strengthening</p>
                  </div>
                  <div className="col-md-3">
                    <p>Ergonomic assessment</p>
                    <p>Gait analysis</p>
                    <p>Movement analysis</p>
                  </div>
                  <div className="col-md-3">
                    <p>Return to golf</p>
                    <p>Return to gym</p>
                    <p>Return to running</p>
                  </div>
                  <div className="col-md-3">
                    <p>Return to throwing</p>
                    <p>Strengthening program</p>
                    <p>Vestibular rehab</p>
                  </div>
                </div>
              </div>
              {/* end of menu data */}
            </div>
          </div>
          {/* Mobile Accordian */}
          <div id="accordion" className="mob-accordian">
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="card-link" data-toggle="collapse" href="#collapseOne"><img
                  src="/assets/img/desk-icons-1.png" alt=""/> Orthopedic Injuries</a>
              </div>
              <div id="collapseOne" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p>Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#collapseTwo"><img
                  src="/assets/img/desk-icons-2.png" alt=""/>Senior Care</a>
              </div>
              <div id="collapseTwo" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p>Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#collapseThree"><img
                  src="/assets/img/desk-icons-3.png" alt=""/> Post-Surgical Rehabilitation</a>
              </div>
              <div id="collapseThree" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p>Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#collapseFour"><img
                  src="/assets/img/desk-icons-4.png" alt=""/> Back and Neck Pain</a>
              </div>
              <div id="collapseFour" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p>Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#collapseFive"><img
                  src="/assets/img/desk-icons-5.png" alt=""/>Specialty Care</a>
              </div>
              <div id="collapseFive" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p>Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card ca-shadow-sm rounded bg-white">
              <div className="card-header">
                <a className="collapsed card-link" data-toggle="collapse" href="#collapsesix"><img
                  src="/assets/img/desk-icons-5.png" alt=""/>Focused Train</a>
              </div>
              <div id="collapsesix" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row text-left px-3">
                    <div className="col-sm-6">
                      <p>Adhesive capsulitis</p>
                      <p>Buritis</p>
                      <p>carpal tunnel syndrome</p>
                      <p>Dislocation</p>
                      <p>Fracture</p>
                      <p>Meniscus tear</p>
                      <p>Patellofermoral syndrome</p>
                      <p>Piriformis syndrome</p>
                      <p>Plantar fasciitis</p>
                      <p>Shoulder impingement</p>
                    </div>
                    <div className="col-sm-6">
                      <p>Golfer's elbow</p>
                      <p>Lliotibial band syndrome</p>
                      <p>Joint Pain</p>
                      <p>Labral tear</p>
                      <p>Ligament tear</p>
                      <p>Shin splints</p>
                      <p>Sprain/strain</p>
                      <p>Tendonitis</p>
                      <p>Tennis elbow</p>
                      <p id="booking">Thoracic outlet syndrome</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <div className="pb-4 mt-5">
            <h2>Request a therapist</h2>
            <p>Let's get physical shall we? Please complete the form below. And make<br/>
              sure to hit submit, once the form is filled out completely.</p>
          </div>
          <div className="therapist-form ca-shadow-sm rounded p-4 bg-white w-75 m-auto">
            <ScheduleForm />
          </div>
          <h3 className="my-5 py-5"><a href="tel:888-859-0145">or call 888-859-0145</a></h3>
        </div>
      </div>
      {/* End Treatement and care */}

    </main>
  );
}

const TreatmentCarePage = () => {
  return (
    <Layout>
      <TreatmentCarePageTemplate />
    </Layout>
  )
};


export default TreatmentCarePage;
